<template>
    <div style="width: 100%; height: 100%">
        <div v-if="layoutData.length > 0">
            <grid-layout
                v-bind="options"
                :is-draggable="false"
                :is-resizable="false"
                :layout.sync="layoutData"
                :is-mirrored="false"
                :vertical-compact="false"
                :use-css-transforms="false"
            >
                <grid-item
                    v-for="item in layoutData"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item.i"
                >
                    <component v-if="item.type" :is="`${item.type}-item`" :item="item" :isRemovable="false" />
                </grid-item>
            </grid-layout>
        </div>
        <slot name="empty" v-else></slot>
    </div>
</template>

<script>
import { GridItem, GridLayout } from "vue-grid-layout"
import { Pane, Splitpanes } from "splitpanes"
import "splitpanes/dist/splitpanes.css"
import ChartItem from "./items/ChartItem.vue"
import PageItem from "./items/PageItem.vue"

export default {
    name: "PlatonGrid",

    components: {
        splitpanes: Splitpanes,
        Pane,
        GridLayout,
        GridItem,
        ChartItem,
        PageItem
    },

    props: {
        layout: {
            required: true,
            type: Array
        },

        options: {
            type: Object,
            default: () => ({
                margin: [5, 5],
                colNum: 12,
                rowHeight: 30
            })
        },

        mode: {
            type: String,
            default: "index"
        }
    },

    data() {
        return {
            layoutData: this.layout
        }
    },

    computed: {
        modeIndex() {
            return this.mode === "index" ? "i" : "name"
        }
    }
}
</script>

<style>
.splitpanes__pane {
    position: relative;
}
</style>
